import { CDN2 } from '@/helpers/api';

export const oldImages = [
  `${CDN2()}/staticmyapp/cards-default-image.webp`,
  `${CDN2()}/staticmyapp/pvd-airport.webp`,
  `${CDN2()}/staticmyapp/default-image-3.webp`,
];

export const blindBookingImages = Array.from(
  { length: 10 },
  (_, index) =>
    `${CDN2()}/staticmyapp/unbranded/BlindBooking/BlindBooking${index + 1}.webp`
);

export const hiddenValueImages = Array.from(
  { length: 10 },
  (_, index) =>
    `${CDN2()}/staticmyapp/unbranded/HiddenValue/HiddenValue${index + 1}.webp`
);

export const revealedOnBookingImages = Array.from(
  { length: 10 },
  (_, index) =>
    `${CDN2()}/staticmyapp/unbranded/RevealedonBooking/RevealedonBooking${
      index + 1
    }.webp`
);

export const unbrandedLocationImages = Array.from(
  { length: 10 },
  (_, index) =>
    `${CDN2()}/staticmyapp/unbranded/UnbrandedLocation/UnbrandedLocation${
      index + 1
    }.webp`
);

export const unlockSavingsImages = Array.from(
  { length: 10 },
  (_, index) =>
    `${CDN2()}/staticmyapp/unbranded/UnlockSavings/UnlockSavings${
      index + 1
    }.webp`
);
